<template>
  <div class="ly_news_cb_main">
      <div class="ly_news_cb_title">数字PCR定值服务</div>
    <div class="ly_news_cb_hot_so">1、简介</div>
    <p>数字PCR是通过将微量样本做高倍稀释和分液，使每个反应单元含有不超过一个模板分子，再将所有样品进行PCR扩增，并对扩增结果以阳性或隐性直按计数统计分析的一种技术。相较于qPCR，数字PCR可直接数出DNA分子的个数，是对起始样品的绝对定量，且不依赖扩增效率，能克服PCR抑制剂的影响；适合血液组织，FFFE组织，粪便，尿液，痰液，水样，土壤，植物等复杂样品中DNA的绝对定量。数字PCR拥有更好的精确度，精密度和重复性，可以做用于精确测定靶基因的相对表达，基因拷贝数变异分析等。</p>
    <p>目前应用比较广泛的数字PCR技术主要包括微滴式数字PCR（droplet digital PCR，RT-RT-ddPCR）和芯片式数字PCR等。</p>
    <p>①微滴式数字PCR是利用油包水技术将反应液分割为数万个纳米级大小的微滴发生器，每一个微滴都是一个独立的PCR反应体系，其中每个微滴或不含待检核酸靶分子，或者含有一个至数个待检核酸靶分子。每个微滴都作为一个独立的PCR反应器。对每个微滴进行检测，有荧光信号的微滴判读为1，没有荧光信号的微滴判读为0，最终根据泊松分布原理以及阳性微滴的比例，分析软件可计算给出待检靶分子的浓度或拷贝数。</p>
    <p>②微流控芯片数字PCR是采用微流控芯片将PCR反应液分割成数量众多且体积相等的反应单元，通过检测扩增后荧光信号呈阳性的反应单元的数量对核酸模块进行定量。</p>
    <p>北纳生物现开启数字PCR定值服务，可接受常规菌株、灭活病毒液核酸提取及定值服务，满足您的实验需求。</p>

    <div class="ly_news_cb_hot_so">2、实验流程</div>
    <img src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/667f3b9874144c34c73777afcf277cde.png" alt="流程">
    <div class="ly_news_cb_hot_so">3、送样要求</div>
    <p>（1）菌株：请您提供给我们由至少分离三次以上的单菌落培养的平板或斜面，如果因实验样品不纯造成的检测特异性不好，本公司将收取相应实验费用；</p>
    <p>（2）如果您的菌体带有致病性，请您提供给我们菌体的基因组DNA，并将其进行加热处理以杀死致病菌；</p>
    <p>（3）基因组DNA：样品浓度不低于10ng/μl，总体积不少于50μl；</p>
    <p>（4）灭活病毒液，不低于10^5TCID50/mL；</p>
    <p>（5）如有指定的引物探针可提供序列。</p>
    <div class="ly_news_cb_hot_so">4、送样流程</div>
    <p>（1）下载附件<a href="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/0eb3759d08ab81359e58a50d3b2334e5.xls"  rel="nofollow">《BNCC数字PCR定值服务订单》</a>，并详细填写其中的内容；具体信息请联系客服</p>
    <p>（2）寄送样本到本公司</p>
    <div class="ly_news_cb_hot_so">5、提供结果</div>
    <p>（1）数字PCR定值数据及定值产品</p>
    <p>（2）定值报告一份</p>
    <div class="ly_news_cb_hot_so">6、其它</div>
    <p>数字PCR定值需10至15个工作日。</p>
  </div>
  <footer>
    <ul class="footer-nav">
      <li class="footer-li">
        <a
          href="https://tb.53kf.com/code/client/099be4cda651b04a92a215ad1e6edd8d8/1"
        >
          <!-- <img src="https://www.bzwz.com/static/m/images/icon52.png" alt="" /> -->
          <img src="@/assets/img/common/icon52.png" alt="" />
          客服
        </a>
      </li>

      <li class="footer-li button download" @click="onBack()" style="background-color: #df0024">
        <a href="javascript:;"> 返回 </a>
      </li>
    </ul>
  </footer>
</template>

<script lang="ts">
import {
  defineComponent
} from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "identiFication",
  setup() {
    const router = useRouter();
    function onBack() {
      router.back()
    }
    return {
      onBack
    };
  },
});
</script>


<style lang="scss" scoped>
.ly_news_cb_main {
  padding: 15px;
  font-size: 0.42667rem;
  line-height: 0.66667rem;
  color: #666;
  padding-bottom: 1.33333rem;
}
img{
    width:100%;
}
a{
    color:#05b3d2;
}
p {
  margin: 1em 0;
  word-break: break-word;
  text-indent: 20px;
}

.ly_news_cb_title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #444;
}

.ly_news_cb_hot_so {
  font-weight: 600;
  color: #444;
  font-size: 18px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1px solid #f4f4f4;
  font-size: 14px;
  color: #666;
  background-color: #fff;
  z-index: 100;
}
footer a {
  font-size: 14px;
  color: #666;
  display: block;
}

.footer-li {
  float: left;
  width: 17%;
  text-align: center;
  position: relative;
}
.footer-li img {
  display: block;
  width: 19.5px;
  height: 19.5px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -9.75px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #df0024;
}

.footer-nav .active .active-status {
  display: block;
}

footer .button {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #e7c527;
  color: #fff;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 10px;
}
footer .button a {
  color: #fff;
}
.download {
  float: right;
}
</style>